export const Modules = {
  ADMIN: "Admin",
  PROFILE: "Profile",
  REGISTER: "Register",
  LOGIN: "Login",
  DASHBOARD: "Dashboard",
  SEARCH: "Search",
  TAGS: "Tags",
  UPLOAD: "Upload",
};
