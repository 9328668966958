// import React, { useState, useEffect, useMemo } from "react";
// import Table from "../Utilities/Table/Table";
// import { getData, postData } from "../Services/AccessAPI";
// import { toast } from "react-toastify";
// import { Button, CircularProgress, Tooltip } from "@mui/material";
// import LoadingSpinner from "../Utilities/LoadingSpinner";
// import "./Admin.css";
// import { API_MODULES } from "../Services/Settings";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faAngleRight, faAnglesRight } from "@fortawesome/free-solid-svg-icons";

// const Admin = () => {
//   const [activeTab, setActiveTab] = useState("affiliations");
//   const [affiliations, setAffiliations] = useState([]);
//   const [groups, setGroups] = useState([]);
//   const [features, setFeatures] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [isViewClicked, setIsViewClicked] = useState(false);
//   const [selectedAffiliationId, setSelectedAffiliationId] = useState(null);
//   const [groupFeatureMap, setGroupFeatureMap] = useState({});
//   const [selectedGroup, setSelectedGroup] = useState(null);
//   const [checkedGroups, setCheckedGroups] = useState([]);
//   const [checkedFeatures, setCheckedFeatures] = useState([]);
//   const [isUpdating, setIsUpdating] = useState(false);

//   useEffect(() => {
//     const fetchData = async () => {
//       setIsLoading(true);
//       try {
//         const [affiliationsResponse, groupsResponse, featuresResponse] =
//           await Promise.all([
//             getData(API_MODULES.ADMIN, "getAllAffiliations"),
//             getData(API_MODULES.ADMIN, "activeOrganisations"),
//             getData(API_MODULES.ADMIN, "getAllFeatures"),
//           ]);

//         setAffiliations(affiliationsResponse);
//         setGroups(groupsResponse);
//         setFeatures(featuresResponse);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//         toast.error("Error fetching data");
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   const handleTabChange = (tab) => {
//     setActiveTab(tab);
//   };

//   const resetGroupFeatureState = () => {
//     setGroupFeatureMap({});
//     setSelectedGroup(null);
//     setCheckedGroups([]);
//     setCheckedFeatures([]);
//   };
  
//   const handleViewButtonClick = async (affiliate_name, affiliate_id) => {
//     setIsViewClicked(true);
//     setSelectedAffiliationId(affiliate_id);
  
//     try {
//       const response = await getData(
//         API_MODULES.ADMIN,
//         "getAffiliationGroupFeatureMapping",
//         {
//           affiliation_id: affiliate_id,
//         }
//       );
  
//       if (
//         response &&
//         response.length > 0 &&
//         response[0].affiliate_groups_feature
//       ) {
//         const groupFeatureMapping = JSON.parse(
//           response[0].affiliate_groups_feature
//         );
//         setGroupFeatureMap(groupFeatureMapping);
//         setCheckedGroups(Object.keys(groupFeatureMapping));
//         setSelectedGroup(null);
//         setCheckedFeatures([]);
//       } else {
//         resetGroupFeatureState();
//       }
//     } catch (error) {
//       console.error("Error fetching group-feature mapping:", error);
//       toast.error("Error fetching group-feature mapping");
//       resetGroupFeatureState();
//     }
//   };
  
//   const handleGroupViewButtonClick = (groupName) => {
//     setSelectedGroup(groupName);
//     setCheckedFeatures(groupFeatureMap[groupName] || []);
//   };

//   const handleGroupCheckboxChange = (groupName) => {
//     setCheckedGroups((prevCheckedGroups) => {
//       const newCheckedGroups = prevCheckedGroups.includes(groupName)
//         ? prevCheckedGroups.filter((group) => group !== groupName)
//         : [...prevCheckedGroups, groupName];
  
//       setGroupFeatureMap((prevGroupFeatureMap) => {
//         const newGroupFeatureMap = { ...prevGroupFeatureMap };
//         if (newCheckedGroups.includes(groupName)) {
//           newGroupFeatureMap[groupName] = newGroupFeatureMap[groupName] || [];
//         } else {
//           delete newGroupFeatureMap[groupName];
//         }
//         return newGroupFeatureMap;
//       });
  
//       return newCheckedGroups;
//     });
//   };
  
//   const handleFeatureCheckboxChange = (featureName) => {
//     if (selectedGroup) {
//       setCheckedFeatures((prevCheckedFeatures) => {
//         const newCheckedFeatures = prevCheckedFeatures.includes(featureName)
//           ? prevCheckedFeatures.filter((name) => name !== featureName)
//           : [...prevCheckedFeatures, featureName];
  
//         setGroupFeatureMap((prevGroupFeatureMap) => {
//           const newGroupFeatureMap = { ...prevGroupFeatureMap };
//           if (newCheckedFeatures.includes(featureName)) {
//             if (!newGroupFeatureMap[selectedGroup]) {
//               newGroupFeatureMap[selectedGroup] = [];
//             }
//             newGroupFeatureMap[selectedGroup] = [
//               ...new Set([...newGroupFeatureMap[selectedGroup], featureName]),
//             ];
//           } else {
//             newGroupFeatureMap[selectedGroup] = newGroupFeatureMap[selectedGroup].filter(
//               (name) => name !== featureName
//             );
//           }
//           return newGroupFeatureMap;
//         });
  
//         return newCheckedFeatures;
//       });
//     } else {
//       toast.warn("Please select a group first");
//     }
//   };
  

//   const handleUpdateButtonClick = async () => {
//     setIsUpdating(true);
//     const payload = {
//       affiliation_id: selectedAffiliationId,
//       group_ids: groups
//         .filter((group) => checkedGroups.includes(group.group_name))
//         // .filter((group) => selectedGroup === group.group_name)
//         .map((group) => group.group_id),
//       feature_names: checkedFeatures,
//       subscription_name: "gold",
//     };

//     try {
//       const response = await postData(
//         API_MODULES.ADMIN,
//         "attachPoliciesToAffiliation",
//         {},
//         payload
//       );

//       const data = await response.json();

//       if (data.success) {
//         toast.success("Policies updated successfully");
//       } else {
//         toast.error("Failed to update policies");
//       }
//     } catch (error) {
//       console.error("Error updating policies:", error);
//       toast.error("Error updating policies");
//     } finally {
//       setIsUpdating(false);
//     }
//   };

//   const handleClearChanges = async () => {
//     if (selectedAffiliationId) {
//       await handleViewButtonClick(null, selectedAffiliationId);
//     }
//   };

//   const columns = useMemo(
//     () => [
//       {
//         Header: "Affiliation",
//         accessor: "affiliate_name",
//         sortType: "alphanumeric",
//         Cell: ({ row }) => (
//           <div
//             className={`row-item ${
//               row.original.affiliate_id === selectedAffiliationId
//                 ? "selected-item"
//                 : ""
//             }`}
//             onClick={() =>
//               handleViewButtonClick(
//                 row.original.affiliate_name,
//                 row.original.affiliate_id
//               )
//             }
//           >
//             {row.original.affiliate_name}
//             <FontAwesomeIcon
//               icon={row.original.affiliate_id === selectedAffiliationId ? faAnglesRight : faAngleRight}
//               className={`angle-icon ${row.original.affiliate_id === selectedAffiliationId ? 'selected' : ''}`}
//               style={{
//                 color: row.original.affiliate_id === selectedAffiliationId ? "#333" : "#999",
//               }}
//             />
//           </div>
//         ),
//       },
//     ],
//     [selectedAffiliationId]
//   );
  

//   const groupColumns = useMemo(
//     () => [
//       {
//         Header: () => (
//           <div className="custom-header">
//             <Tooltip title="Select All" placement="bottom-start">
//               <input
//                 type="checkbox"
//                 checked={checkedGroups.length === groups.length}
//                 onChange={() =>
//                   setCheckedGroups(
//                     checkedGroups.length === groups.length
//                       ? []
//                       : groups.map((group) => group.group_name)
//                   )
//                 }
//               />
//             </Tooltip>
//           </div>
//         ),
//         accessor: "checkbox",
//         Cell: ({ row }) => (
//           <input
//             type="checkbox"
//             checked={checkedGroups.includes(row.original.group_name)}
//             onChange={() => handleGroupCheckboxChange(row.original.group_name)}
//           />
//         ),
//       },
//       {
//         Header: "Group",
//         accessor: "group_name",
//         sortType: "alphanumeric",
//         Cell: ({ row }) => (
//           <div
//             className={`row-item ${
//               row.original.group_name === selectedGroup ? "selected-item" : ""
//             }`}
//             onClick={() => handleGroupViewButtonClick(row.original.group_name)}
//           >
//             {row.original.group_name}
//             <FontAwesomeIcon
//               icon={row.original.group_name === selectedGroup ? faAnglesRight : faAngleRight}
//               className={`angle-icon ${row.original.group_name === selectedGroup ? 'selected' : ''}`}
//               style={{
//                 color: row.original.group_name === selectedGroup ? "#333" : "#999",
//               }}
//             />
//           </div>
//         ),
//       },
//     ],
//     [selectedGroup, groups, checkedGroups]
//   );
  
  
//   const featureColumns = useMemo(
//     () => [
//       {
//         Header: () => (
//           <div className="custom-header">
//             <Tooltip title="Select All" placement="bottom-start">
//               <input
//                 type="checkbox"
//                 checked={checkedFeatures.length === features.length}
//                 onChange={() =>
//                   setCheckedFeatures(
//                     checkedFeatures.length === features.length
//                       ? []
//                       : features.map((feature) => feature.feature_name)
//                   )
//                 }
//               />
//             </Tooltip>
//           </div>
//         ),
//         accessor: "checkbox",
//         Cell: ({ row }) => (
//           <input
//             type="checkbox"
//             checked={checkedFeatures.includes(row.original.feature_name)}
//             onChange={() =>
//               handleFeatureCheckboxChange(row.original.feature_name)
//             }
//           />
//         ),
//       },
//       {
//         Header: "Feature",
//         accessor: "feature_name",
//         sortType: "alphanumeric",
//       },
//     ],
//     [checkedFeatures, features]
//   );
  

//   return (
//     <div className="admin-container">
//       <div className="admin-header">
//         <div className="admin-title">
//           <div className="background-image" />
//           <h1 className="section-title">
//             <span className="black-on-gold">Admin Portal</span>
//           </h1>
//           <p>Manage affiliations, groups and features.</p>
//         </div>
//         <div className="tabs-container">
//           <div
//             className={`tab ${
//               activeTab === "affiliations" ? "active-tab" : ""
//             }`}
//             onClick={() => handleTabChange("affiliations")}
//           >
//             Affiliations
//           </div>
//           <div
//             className={`tab ${activeTab === "users" ? "active-tab" : ""}`}
//             onClick={() => handleTabChange("users")}
//           >
//             Users
//           </div>
//         </div>
//         <h2 className="container-title">
//           <span>
//             Manage groups and features of{" "}
//             <span className="black-on-gold">
//               {activeTab === "affiliations"
//                 ? "individual affiliations"
//                 : "individual users"}
//             </span>
//           </span>
//         </h2>
//         <div className="table-container">
//           {isLoading && <LoadingSpinner />}
//           <div
//             className={`entity-data ${
//               affiliations.length > 0 ? "" : "full-width"
//             }`}
//           >
//             {affiliations.length > 0 ? (
//               <Table
//                 columns={columns}
//                 data={affiliations}
//                 disableFilterOptionsForIds={["action"]}
//               />
//             ) : (
//               !isLoading && (
//                 <p className="no-data-text">No affiliations found</p>
//               )
//             )}
//           </div>
//           {affiliations.length > 0 && (
//             <div className="entity-view-data">
//               {isViewClicked && (
//                 <>
//                   <div className="entity-side-panel">
//                     <div className="groups-data">
//                       {groups.length > 0 ? (
//                         <Table
//                           columns={groupColumns}
//                           data={groups}
//                           disableFilterOptionsForIds={["checkbox"]}
//                           tableDataHeight={"400px"}
//                           showPagination={false}
//                         />
//                       ) : (
//                         <p className="no-data-text">No groups found</p>
//                       )}
//                     </div>
//                     <div className="features-data">
//                       {selectedGroup && features.length > 0 ? (
//                         <Table
//                           columns={featureColumns}
//                           data={features}
//                           disableFilterOptionsForIds={["checkbox"]}
//                           tableDataHeight={"400px"}
//                           showPagination={false}
//                         />
//                       ) : (
//                         <p className="no-data-text">Select a group</p>
//                       )}
//                     </div>
//                   </div>
//                   <div className="options-container">
//                   <button
//                       type="button"
//                       className="option-button clear"
//                       onClick={handleClearChanges}
//                     >
//                       Clear Changes
//                     </button>
//                     <button
//                       type="button"
//                       className="option-button update"
//                       onClick={handleUpdateButtonClick}
//                       disabled={isUpdating}
//                     >
//                       {isUpdating && (
//                         <CircularProgress
//                           size={20}
//                           style={{ marginRight: "8px", color: "white" }}
//                         />
//                       )}
//                       Update
//                     </button>
//                   </div>
//                 </>
//               )}
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Admin;

import React, { useState, useEffect } from "react";
import { getData, postData } from "../Services/AccessAPI";
import { toast } from "react-toastify";
import Affiliations from "./Affiliations";
import Users from "./Users";
import "./Admin.css";
import { API_MODULES } from "../Services/Settings";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button, RadioGroup, FormControlLabel, Radio, FormControl, Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#8c1d40",
    },
  },
});

const Admin = () => {
  const [activeTab, setActiveTab] = useState("affiliations");
  const [affiliations, setAffiliations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState("Affiliation");
  const [formData, setFormData] = useState({
    affiliationName: "",
    affiliationUrl: "",
    domain: "",
    groupName: "",
    expirationDate: "",
  });

  useEffect(() => {
    fetchAffiliations();
  }, []);

  const fetchAffiliations = async () => {
    setIsLoading(true);
    try {
      const affiliationsResponse = await getData(
        API_MODULES.ADMIN,
        "getAllAffiliations"
      );
      setAffiliations(affiliationsResponse);
    } catch (error) {
      console.error("Error fetching affiliations:", error);
      toast.error("Error fetching affiliations");
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setFormData({
      affiliationName: "",
      affiliationUrl: "",
      domain: "",
      groupName: "",
      expirationDate: ""
    });
  };

  const handleSave = async () => {
    try {
      let payload = {};
      if (dialogType === "Affiliation") {
        payload = {
          affiliation_name: formData.affiliationName,
          domain_url: formData.affiliationUrl,
          domain_name: formData.domain,
          // expiry_date: formData.expirationDate,
        };
        await postData(API_MODULES.ADMIN, "createAffiliation", payload);
        toast.success("Affiliation created successfully");
      } else {
        payload = {
          group_name: formData.groupName,
        };
        await postData(API_MODULES.ADMIN, "createGroup", payload);
        toast.success("Group created successfully");
      }
      // fetchAffiliations();
      handleClose();

      // Refresh the page after success
      window.location.reload();
    } catch (error) {
      console.error("Error saving data:", error);
      toast.error("Error saving data");
    }
  };

  // const handleUrlChange = (e) => {
  //   const value = e.target.value;
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     affiliationUrl: value,
  //   }));

  //   try {
  //     const extractedDomain = new URL(value).hostname.replace("www.", "");
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       domain: `@${extractedDomain}`,
  //     }));
  //   } catch (error) {
  //     console.error("Invalid URL:", error);
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       domain: "",
  //     }));
  //   }
  // };

  return (
    <ThemeProvider theme={theme}>
      <div className="admin-container">
        <div className="admin-header">
          <div className="admin-title">
            <div className="background-image" />
            <h1 className="section-title">
              <span className="black-on-gold">Admin Portal</span>
            </h1>
            <p>Manage affiliations, groups and features.</p>
          </div>
          <div className="tabs-container">
            <div
              className={`tab ${
                activeTab === "affiliations" ? "active-tab" : ""
              }`}
              onClick={() => handleTabChange("affiliations")}
            >
              Affiliations
            </div>
            {/* Hide Panel 2 */}
            {/* <div
              className={`tab ${activeTab === "users" ? "active-tab" : ""}`}
              onClick={() => handleTabChange("users")}
            >
              Users
            </div> */}
          </div>
          <h2 className="container-title">
            <span>
              Manage groups and features of{" "}
              <span className="black-on-gold">
                {activeTab === "affiliations"
                  ? "individual affiliations"
                  : "individual users"}
              </span>
            </span>
            {activeTab === "affiliations" && (
              <Tooltip
                title="Create new affiliation or group"
                placement="bottom-start"
              >
                <FontAwesomeIcon
                  icon={faCirclePlus}
                  className="plus-icon"
                  onClick={handleClickOpen}
                />
              </Tooltip>
            )}
          </h2>
        </div>

        <div className="content-container">
          {activeTab === "affiliations" && (
            <Affiliations
              affiliations={affiliations}
              isLoading={isLoading}
              setAffiliations={setAffiliations}
            />
          )}
          {activeTab === "users" && <Users affiliations={affiliations} />}
        </div>

        {/* Dialog for creating new Affiliation or Group */}
        <Dialog open={openDialog} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle>Create New {dialogType}</DialogTitle>
          <DialogContent>
            <FormControl component="fieldset">
              <RadioGroup
                row
                value={dialogType}
                onChange={(e) => setDialogType(e.target.value)}
              >
                <FormControlLabel
                  value="Affiliation"
                  control={<Radio color="primary" />}
                  label="Affiliation"
                />
                <FormControlLabel
                  value="Group"
                  control={<Radio color="primary" />}
                  label="Group"
                />
              </RadioGroup>
            </FormControl>

            {dialogType === "Affiliation" ? (
              <>
                <TextField
                  autoFocus
                  margin="dense"
                  id="affiliationName"
                  label="Affiliation Name"
                  value={formData.affiliationName}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      affiliationName: e.target.value,
                    })
                  }
                  fullWidth
                  variant="standard"
                />
                <TextField
                  margin="dense"
                  id="affiliationUrl"
                  label="Affiliation URL"
                  value={formData.affiliationUrl}
                  // onChange={handleUrlChange}
                  onChange={(e) =>
                    setFormData({ ...formData, affiliationUrl: e.target.value })
                  }
                  fullWidth
                  variant="standard"
                />
                <TextField
                  margin="dense"
                  id="domain"
                  label="Domain"
                  value={formData.domain}
                  onChange={(e) =>
                    setFormData({ ...formData, domain: e.target.value })
                  }
                  fullWidth
                  variant="standard"
                  // disabled
                />
                {/* <TextField
                  margin="dense"
                  id="expirationDate"
                  label="Expiration Date"
                  type="date"
                  value={formData.expirationDate}
                  onChange={(e) =>
                    setFormData({ ...formData, expirationDate: e.target.value })
                  }
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                /> */}
              </>
            ) : (
              <TextField
                autoFocus
                margin="dense"
                id="groupName"
                label="Group Name"
                value={formData.groupName}
                onChange={(e) =>
                  setFormData({ ...formData, groupName: e.target.value })
                }
                fullWidth
                variant="standard"
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} className="Mui-button">
              Cancel
            </Button>
            <Button onClick={handleSave} className="Mui-button save">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </ThemeProvider>
  );
};

export default Admin;

