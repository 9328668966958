import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Register.css";
import asu_ker_logo from "../../images/asu_ker_logo.png";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { getData, postData, postDataForLoginAndRegister } from "../Services/AccessAPI";
import { API_MODULES, BASE_URL } from "../Services/Settings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@mui/material";

const Register = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [affiliations, setAffiliations] = useState([]);
  const [affiliation, setAffiliation] = useState("");
  const [otherAffiliation, setOtherAffiliation] = useState("");
  const [domain, setDomain] = useState("");
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [dialog, setDialog] = useState({
    message: "",
    data: [],
    show: false,
    isRegistered: false,
  });
  const [emailIdPart, setEmailIdPart] = useState("");
  const [emailError, setEmailError] = useState('');
  const [emailWarning, setEmailWarning] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isAffiliationsDisabled, setIsAffiliationsDisabled] = useState(true);
  const [showOtherAffiliation, setShowOtherAffiliation] = useState(false);
  const [affiliationUrl, setAffiliationUrl] = useState("");
  const [affiliationUrlError, setAffiliationUrlError] = useState('');
  const [otherAffiliationDomain, setOtherAffiliationDomain] = useState("");


  const passwordConstraints = "Password must be at least 6 characters long, contain at least one digit (0-9), one non-alphanumeric character, one uppercase letter (A-Z)."

  const history = useHistory();

  useEffect(() => {
    // Fetch all affiliations when component mounts
    const fetchAllAffiliations = async () => {
      try {
        const response = await getData(API_MODULES.REGISTER, 'getAllAffiliations');
        if (response.success) {
          // Filter out invalid affiliations
          const validAffiliations = response.data.filter(
            (aff) => aff.affiliate_id !== 0 && aff.affiliate_name !== null
          );
          setAffiliations([...validAffiliations, { affiliate_id: -1, affiliate_name: "Other" }]);
          setIsAffiliationsDisabled(false);
        } else {
          toast.error("Failed to fetch affiliations");
        }
      } catch (error) {
        console.error("Error fetching affiliations:", error);
        toast.error("Error fetching affiliations");
      }
    };

    fetchAllAffiliations();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "firstName") {
      setFirstName(value);
    } else if (name === "lastName") {
      setLastName(value);
    } else if (name === "affiliation") {
      setAffiliation(value);
      setShowOtherAffiliation(value === "Other");
      setDomain(affiliations.find(aff => aff.affiliate_name === value)?.domain_name || ""); // Assuming each affiliation has an `affiliate_domain`
    } else if (name === "otherAffiliation") {
      setOtherAffiliation(value);
    } else if (name === "affiliationUrl") {
      setAffiliationUrl(value);
    } else if (name === "emailIdPart") {
      setEmailIdPart(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "title") {
      setTitle(value);
    } else if (name === "password") {
      setPassword(value);
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  const handleInputFocus = (e) => {
    const inputContainer = e.target.parentNode;
    inputContainer.classList.add("focused");
  };

  const handleInputBlur = (e) => {
    const inputContainer = e.target.parentNode;
    if (e.target.value === "") {
      inputContainer.classList.remove("focused");
    }
    if (e.target.name === "emailIdPart" && e.target.value !== "") {
      handleEmailBlur(e);
    } else if (e.target.name === "affiliationUrl") {
      handleAffiliationUrlBlur(e);
    }
  };

  const handleAffiliationUrlBlur = (e) => {
    let value = e.target.value;
    if (value) {
      // Prepend https:// if the URL doesn't start with http:// or https://
      if (!/^https?:\/\//i.test(value)) {
        value = `https://${value}`;
      }
      try {
        const extractedDomain = new URL(value).hostname.replace('www.', '');
        let errorMessage = "";
        if(affiliations.some(aff => aff.domain_name === `@${extractedDomain}`)) {
          errorMessage = "Provided Affiliation already exists in our Database. Please check the affiliation dropdown.";
        }
        setAffiliationUrlError(errorMessage);
        setOtherAffiliationDomain(`@${extractedDomain}`);
      } catch (error) {
        console.error("Invalid URL:", error);
        setOtherAffiliationDomain("");
      }
    } else {
      setOtherAffiliationDomain("");
    }
  };

  const checkEmailExists = async (email) => {
    try {
      const response = await fetch(BASE_URL + `api/Registration/validate-email?email=${encodeURIComponent(email)}`);
      const data = await response.json();

      if (data.success && data.message=="Email resent successfully. Please check your email to confirm your account.") {
        setEmailWarning('If you have already signed up but haven’t confirmed your account, a new confirmation email will be sent to you shortly.');
        setEmailError('');
      }
      else if(data.success){
        
      }
      else {
        setEmailWarning('');
        setEmailError('Email already exists');
      }

      // if(!data.data?.isAvailable) {
      //   data.data?.isEmailConfirmed
      //   ? setEmailError(data.message)
      //   : setDialog((prev) => ({ ...prev, show: true, message: data.message, isRegistered: true }));
      // } else {
      //   setEmailError('');
      // }
    }
    catch (error) {
      console.error(error);
    }
  };

  const handleEmailBlur = async (e) => {
    const emailValue = `${emailIdPart?.split('@')[0]}${domain}`;
    if (emailValue) {
      await checkEmailExists(emailValue);
    }
  };

  const validatePassword = (password) => {
    let errorMessage = '';
  
    // Check for minimum length
    if (password.length < 6) {
      errorMessage += 'Password must be at least 6 characters long. ';
    }
  
    // Check for a digit
    if (!/\d/.test(password)) {
      errorMessage += 'Password must contain at least one digit (0-9). ';
    }
  
    // Check for a non-alphanumeric character
    if (!/\W/.test(password) && !/_/.test(password)) { // \W is non-word characters, added underscore as it's considered a word char
      errorMessage += 'Password must have at least one non-alphanumeric character. ';
    }
  
    // Check for an uppercase letter
    if (!/[A-Z]/.test(password)) {
      errorMessage += 'Password must have at least one uppercase letter (A-Z). ';
    }
  
    return errorMessage.trim(); // Trim any trailing space
  };
  
  const handlePasswordBlur = (e) => {
    handleInputBlur(e);
    if(password !== "") {
      const validationMessage = validatePassword(password);
      setPasswordError(validationMessage);
    }
  };
  

  const handlePasswordMismatchBlur = (e) => {
    handleInputBlur(e);
    if (!passwordError && confirmPassword) {
      const errorMessage =
        password !== confirmPassword ? "Passwords do not match" : "";
      setPasswordError(errorMessage);
    }
  };

  const handleClose = () => {
    if(dialog.isRegistered) {
      // If user is registered
      // redirect to login
      window.location.href = "/login";
    }
    setDialog((prev) => ({
      ...prev,
      show: false,
    }));
  };

  // const handleResendEmail = async () => {
  //   const loadId = toast.loading("Sending email");
  //   try {
  //     const emailValue = `${emailIdPart}${showOtherAffiliation ? otherAffiliationDomain : domain}`;
  //     const  params = {
  //       email: emailValue
  //     };
  //     const response = await postData(API_MODULES.REGISTER, 'resendEmail', params);
  //     const result = await response.json();

  //     if (result.success) {
  //       setDialog({
  //         message: result.message,
  //         data: [],
  //         show: true,
  //         isRegistered: true,
  //         showResendEmailDialog: false,
  //       });
  //       toast.update(loadId, {
  //         render: "Verification email initiated!",
  //         type: "success",
  //         isLoading: false,
  //         autoClose: 4000,
  //         closeOnClick: true,
  //       });
  //     } else {
  //       setDialog({
  //         message: result.message,
  //         data: result.data,
  //         show: true,
  //         isRegistered: false,
  //         showResendEmailDialog: false,
  //       });
  //       toast.update(loadId, {
  //         render: "One or more errors occurred!",
  //         type: "error",
  //         isLoading: false,
  //         autoClose: 4000,
  //         closeOnClick: true,
  //       });
  //     }
  //   } catch (error) {
  //     console.error("resend verification email error:", error);
  //     toast.update(loadId, {
  //       render: "Network or server error occurred",
  //       type: "error",
  //       isLoading: false,
  //       autoClose: 4000,
  //       closeOnClick: true,
  //     });
  //   }
  // }

  const handleRegistration = async (e) => {
    e.preventDefault();
  
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
  
    const loadId = toast.loading("Validating Information");
  
    try {
      const emailIdPartTrimmed = emailIdPart?.split('@')[0] || ""; // Make sure it’s only the username part
      const emailValue = `${emailIdPartTrimmed}${showOtherAffiliation ? otherAffiliationDomain : domain}`;
      // const emailValue = `${emailIdPart?.split('@')[0]}${showOtherAffiliation ? otherAffiliationDomain : domain}`;
      const userInfo = {
        email: emailValue,
        password,
        FirstName: firstName,
        LastName: lastName,
        affiliationId: showOtherAffiliation ? -1 : affiliations.find(aff => aff.affiliate_name === affiliation)?.affiliate_id || null,
        Title: title,
      };
  
      if (showOtherAffiliation) {
        userInfo.domain_name = otherAffiliationDomain;
        userInfo.affiliation_name = otherAffiliation;
        userInfo.affiliation_url = affiliationUrl;
      }
  
      const response = await postData(API_MODULES.REGISTER, "register", userInfo);
      const result = await response.json();
  
      if (result.success) {
        setDialog({
          message: result.message,
          data: [],
          show: true,
          isRegistered: true,
        });
        toast.update(loadId, {
          render: "Registration successful!",
          type: "success",
          isLoading: false,
          autoClose: 4000,
          closeOnClick: true,
        });
      } else {
        setDialog({
          message: result.message,
          data: result.data,
          show: true,
          isRegistered: false,
        });
        toast.update(loadId, {
          render: "One or more errors occurred!",
          type: "error",
          isLoading: false,
          autoClose: 4000,
          closeOnClick: true,
        });
      }
    } catch (error) {
      console.error("Registration error:", error);
      toast.update(loadId, {
        render: "Network or server error occurred",
        type: "error",
        isLoading: false,
        autoClose: 4000,
        closeOnClick: true,
      });
    }
  };
  

  return (
    <div className="register-container">
      <div className="register-box">
        <img src={asu_ker_logo} alt="Register" className="login-image" />
        <h2>Registration - Knowledge Alliance Tool</h2>
        <form onSubmit={handleRegistration}>
          <div className="name-fields">
            <div className="input-container first-name">
              <input
                type="text"
                name="firstName"
                value={firstName}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                required
              />
              <label>First Name</label>
            </div>
            <div className="input-container">
              <input
                type="text"
                name="lastName"
                value={lastName}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                required
              />
              <label>Last Name</label>
            </div>
          </div>
          <div className="input-container">
            <select
              name="affiliation"
              value={affiliation}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              required
            >
              <option value="" className="dropdown-placeholder" disabled>
                Select Affiliation
              </option>
              {affiliations.map((aff) => (
                <option key={aff.affiliate_id} value={aff.affiliate_name}>
                  {aff.affiliate_name}
                </option>
              ))}
            </select>
            <label>Affiliation</label>
          </div>
          {showOtherAffiliation && (
            <>
              <div className="input-container">
                <input
                  type="text"
                  name="otherAffiliation"
                  value={otherAffiliation}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  required
                />
                <label>Enter Your Affiliation</label>
              </div>
              <div className="input-container">
                <input
                  type="text"
                  name="affiliationUrl"
                  value={affiliationUrl}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  required
                />
                <label>Enter Affiliation URL</label>
                {affiliationUrlError && (
                  <div className="error-message">{affiliationUrlError}</div>
                )}
              </div>
            </>
          )}
          {((affiliation && affiliation !== "Other") ||
            (showOtherAffiliation &&
              otherAffiliationDomain &&
              !affiliationUrlError)) && (
            <div className="input-container">
              <div className="email-container">
                <input
                  type="text"
                  name="emailIdPart"
                  value={emailIdPart}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  required
                />
                <span className="email-domain">
                  {showOtherAffiliation ? otherAffiliationDomain : domain}
                </span>
                <label>Email ID</label>
              </div>
              {emailError && <div className="error-message">{emailError}</div>}
              {emailWarning && <div className="warning-message">{emailWarning}</div>}
            </div>
          )}
          <div className="input-container">
            <input
              type="text"
              name="title"
              value={title}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
            />
            <label>Title (Optional)</label>
          </div>

          <div className="input-container">
            <input
              type="password"
              name="password"
              value={password}
              minLength="6"
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onBlur={handlePasswordBlur}
              required
            />
            <label>Password</label>
            <span className="info-icon">
              <Tooltip title={passwordConstraints} placement="bottom-start">
                <FontAwesomeIcon icon={faCircleInfo} />
              </Tooltip>
            </span>
            {passwordError && (
              <div className="error-message">{passwordError}</div>
            )}
          </div>
          <div className="input-container">
            <input
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              minLength="6"
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onBlur={handlePasswordMismatchBlur}
              required
            />
            <label>Confirm Password</label>
          </div>
          <button type="submit">Register</button>
        </form>
        <Link to="/login">
          <span className="login-btn">Back to login</span>
        </Link>
      </div>
      <Dialog
        open={dialog.show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Registration</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="dialog-description"
          >
            <p>{dialog.message}</p>
            {dialog.data && dialog.data.length > 0 && (
              <div>
                {dialog.data.map((error, index) => (
                  <p key={index}>{error}</p>
                ))}
              </div>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="Mui-button">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Dialog
        open={dialog.showResendEmailDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Resend Verification Link</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="dialog-description"
          >
            It appears that this email is already registered. Would you like to resend the verification link to the registered email address?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="Mui-button">
            Cancel
          </Button>
          <Button
            onClick={handleResendEmail}
            className="Mui-button delete"
          >
            Resend
          </Button>
        </DialogActions>
      </Dialog> */}
    </div>
  );
};

export default Register;
