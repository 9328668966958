import React, { useState } from "react";
import asu_ker_logo from "../../images/asu_ker_logo.png";
import { toast } from "react-toastify";
import { postDataForLoginAndRegister } from "../Services/AccessAPI";
import SessionManager from "./SessionManager";
import { Link } from "react-router-dom";
import "./Login.css";
import "./ForgotPassword.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    }
  };

  const handleInputFocus = (e) => {
    const inputContainer = e.target.parentNode;
    inputContainer.classList.add("focused");
  };

  const handleInputBlur = (e) => {
    const inputContainer = e.target.parentNode;
    if (e.target.value === "") {
      inputContainer.classList.remove("focused");
    }
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleSubmit = async (e) => {
    // try {
    //   e.preventDefault();
    //   const loadId = toast.loading("Validating Credentials");
    //   const userInfo = {
    //     email: email,
    //     password: password,
    //     rememberMe: true,
    //   };

    //   postDataForLoginAndRegister("api/Login", userInfo).then((result) => {
    //     if (result?.data?.access_token) {
    //       SessionManager.setUserSession(
    //         result.data.access_token,
    //         result.data.userId,
    //         email
    //       );

    //       if (SessionManager.getToken()) {
    //         console.log("Login successful!");
    //         toast.update(loadId, {
    //           render: "Login successful!",
    //           type: "success",
    //           isLoading: false,
    //           autoClose: 4000,
    //           hideProgressBar: false,
    //           closeOnClick: true,
    //         });
    //         // If login successful and get token
    //         // redirect to dashboard
    //         window.location.href = "/dashboard";
    //       }
    //     } else {
    //       toast.update(loadId, {
    //         render: "Incorrect Login Credentials!",
    //         type: "error",
    //         isLoading: false,
    //         autoClose: 4000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //       });
    //       // toast.error("Incorrect Username or Password");
    //     }
    //   });
    // } catch (error) {
    //   console.error("Error Logging in: ", error);
    // }
  };

  return (
    <div>
      {/* <App /> */}
      <div className="forgot-password-container">
        <div className="login-box">
          <img src={asu_ker_logo} alt="Login" className="login-image" />
          <h2>Knowledge Alliance Tool</h2>
          <span className="info">Enter the email associated with your account</span>
          <form onSubmit={handleSubmit}>
            <div className="input-container">
              <input
                type="email"
                name="email"
                value={email}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                onKeyDown={onKeyDown}
                required
              />
              <label>Email</label>
            </div>
            <button type="submit">Submit</button>
          </form>
          <Link to="/login">
            <span className="login-btn">Back to login</span>
          </Link>
        </div>
        <div className="login-footer-info">
          <h1>Knowledge Exchange For Resilience</h1>
          <p>©2018 All Rights Reserved. Knowledge Exchange For Resilience!</p>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
