import { toast } from "react-toastify";
import SessionManager from "../Auth/SessionManager";
import { BASE_URL, FAST_API_BASE_URL, API_ENDPOINTS, NEW_AIIR_API_BASE_URL, UPLOADS_BASE_URL } from "./Settings";
import { replacePlaceholders, replacePlaceholdersForAiir } from "../Utilities/utils";

// const fetchData = async (module, endpointKey, params, method, inputObj, returnJSON = false) => {
//     const token = SessionManager.getToken();
//     const headers = {
//         "access-control-allow-origin": "*",
//         // 'Accept': 'application/json',
//         'Content-Type': 'application/json',
//         'Authorization': 'Bearer ' + token
//     };

//     let payload = {
//         method,
//         headers
//     };

//     // if (method === 'POST' || method === 'PUT') {
//     //     payload.body = JSON.stringify(inputObj);
//     // }

//     let endPointTemplate = API_ENDPOINTS[module][endpointKey];
//     if (!endPointTemplate) {
//         throw new Error(`Endpoint template for ${endpointKey} in ${module} not found.`);
//     }
//     const endPoint = replacePlaceholders(endPointTemplate, params);

//     try {
//         const response = await fetch(BASE_URL + endPoint, payload);
//         if (!response.ok) {
//             throw new Error(response.statusText);
//         }
//         if (!returnJSON) {
//             return response;
//         }
//         const result = await response.json();
//         return result;
//     } catch (error) {
//         console.log(error);
//         throw error;
//     }
// };

const fetchData = async (module, endpointKey, params, method, inputObj, returnJSON = false) => {
    let token = SessionManager.getToken();
    const userId = localStorage.getItem("user_id");
    const refreshToken = localStorage.getItem("refresh_token"); // temporary
  
    let headers = {
      "access-control-allow-origin": "*",
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    };
  
    let payload = {
      method,
      headers
    };
  
    if (inputObj && (method === 'POST' || method === 'PUT' || method === 'PATCH')) {
      payload.body = JSON.stringify(inputObj);
    }
  
    let endPointTemplate = API_ENDPOINTS[module][endpointKey];
    if (!endPointTemplate) {
      throw new Error(`Endpoint template for ${endpointKey} in ${module} not found.`);
    }
    const endPoint = replacePlaceholders(endPointTemplate, params);
  
    try {
      let response = await fetch(BASE_URL + endPoint, payload);
      if (!response.ok) {
        // If access token is expired, try to refresh it
        if (response.status === 401) {
          const url = new URL(`${BASE_URL}Login/RefreshToken`);
          url.searchParams.append('userId', userId);
          url.searchParams.append('refreshToken', refreshToken);

          let headers = {
            "access-control-allow-origin": "*",
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          };
  
          const refreshResponse = await fetch(url.toString(), {
            method: 'POST',
            // credentials: 'include', // Include cookies (refresh token)
            headers,
          });
  
          if (refreshResponse.ok) {
            const refreshResult = await refreshResponse.json();
            SessionManager.setUserSession(
              refreshResult.data.access_token,
              localStorage.getItem("userName"),
              refreshResult.data.refresh_token
            );
            token = refreshResult.data.access_token;
            headers.Authorization = 'Bearer ' + token;
            payload.headers = headers;
            response = await fetch(BASE_URL + endPoint, payload);
          } else if(refreshResponse.status === 403 || refreshResponse.status === 401) {
            // Force logout
            SessionManager.removeUserSession();
            window.location.href = '/login';
            throw new Error('Session expired. Please log in again.');
          }
        } else {
          throw new Error(response.statusText);
        }
      }
      if (!returnJSON) {
        return response;
      }
      const result = await response.json();
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

export const getData = async (module, endpointKey, params = {}, returnJSON = true) => {
    return fetchData(module, endpointKey, params, 'GET', null, returnJSON);
};

export const postData = async (module, endpointKey, params = {}, inputObj) => {
    return fetchData(module, endpointKey, params, 'POST', inputObj);
};

export const deleteData = async (module, endpointKey, params = {}) => {
    return fetchData(module, endpointKey, params, 'DELETE', null);
};

export const putData = async (module, endpointKey, params = {}, obj) => {
    return fetchData(module, endpointKey, params, 'PUT', obj);
};

export const postDataForLoginAndRegister = async (type, userData) => { // Not being used; can be removed
    const payload = {
        method: 'POST',
        headers: {
            "access-control-allow-origin": "*",
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(userData)
    };

    try {
        const response = await fetch(BASE_URL + type, payload);
        const result = await response.json();
        return result;
    } catch (error) {
        toast.error("Bad Request");
        console.log(error);
    }
};

export const postDataForUpload = async (params, inputObj) => {
    const token = SessionManager.getToken();
    const payload = {
        method: 'POST',
        headers: {
            "access-control-allow-origin": "*",
            'Authorization': 'Bearer ' + token
        },
        body: inputObj
    };

    const endPoint = replacePlaceholdersForAiir(UPLOADS_BASE_URL, params);

    try {
        const response = await fetch(endPoint, payload);
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const getDataFromFastAPI = async (endPoint, returnJSON = true) => {
    const payload = {
        method: 'GET',
        headers: {
            "access-control-allow-origin": "*",
            'Content-Type': 'application/json'
        }
    };

    try {
        const response = await fetch(FAST_API_BASE_URL + endPoint, payload);
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        if(!returnJSON) {
            return response;
        }
        const result = await response.json();
        return result;
    } catch (error) {
        console.log(error);
    }
};

export const getDataFromAiirAPI = async (params, returnJSON = true) => {
  const token = SessionManager.getToken();
  const payload = {
    method: 'GET',
    headers: {
        "access-control-allow-origin": "*",
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }
  };

  const endPoint = replacePlaceholdersForAiir(NEW_AIIR_API_BASE_URL, params);

  try {
      const response = await fetch(endPoint, payload);
      if (!response.ok) {
          throw new Error(response.statusText);
      }
      if(!returnJSON) {
          return response;
      }
      const result = await response.json();
      return result;
  } catch (error) {
      console.log(error);
  }
}
