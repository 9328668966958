import React, { useEffect, useState } from "react";
import { getData } from "../Services/AccessAPI";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  // Title,
  Tooltip,
  // Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "./SearchesByMonth.css";
import { API_MODULES } from "../Services/Settings";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  // Title,
  Tooltip
  // Legend
);

const SearchesByMonth = () => {
  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getData(API_MODULES.DASHBOARD, 'getSearchesByMonth');
        const reversedData = data.data.reverse();
        const dataToSet = reversedData.slice(1); // Exclude the first element
        setSearchData(dataToSet);
      } catch (error) {
        console.error("Error fetching Searches By Month Data:", error);
      }
    };

    fetchData();
  }, []);

  const labels = searchData.map((data) => data.month);
  const searchCounts = searchData.map((data) => data.searchCount);

  const data = {
    labels,
    datasets: [
      {
        label: "Search Count",
        data: searchCounts,
        backgroundColor: "#ffc627",
        borderColor: "#d9a51d",
        borderWidth: 2,
        hoverBackgroundColor: "#8c1d40", // Set the hover color for the bars
        hoverBorderColor: "#b24f6e",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false, // Allow the chart to have a fixed height
    responsive: true, // Enable responsiveness to adjust the width
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0,
          color: "#333333", // Set the color of y-axis text
        },
        title: {
          display: false,
          text: "Search Count",
        },
      },
      x: {
        title: {
          display: false,
          text: "Month",
        },
        ticks: {
          color: "#333333", // Set the color of x-axis text
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true, // Enable tooltips
        titleColor: "#ffffff", // Set the color of the tooltip title
        bodyColor: "#ffffff", // Set the color of the tooltip body text
        displayColors: false, // Hide the color indicator in tooltips
        callbacks: {
          label: (context) => {
            // Customize the tooltip label
            const label = context.dataset.label || "";
            const value = context.parsed.y || 0;
            return `${label}: ${value}`;
          },
        },
      },
    },
  };

  return (
    <div className="chart-container">
      <h2 className="container-title">Searches by Month</h2>
      <div className="chart-wrapper">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default SearchesByMonth;
