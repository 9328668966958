import React, { useState, useEffect } from "react";
import { getData, postData } from "../Services/AccessAPI";
import { toast } from "react-toastify";
import "./Profile.css";
import { Button, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { API_MODULES } from "../Services/Settings";

const Profile = () => {
  const [activeTab, setActiveTab] = useState("profile"); // "profile" or "password"

  // State for profile data
  const [profileData, setProfileData] = useState({
    firstName: "",
    lastName: "",
    affiliation: "",
    email: "",
    title: "",
  });

  // State for password data
  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  // Error and validation states for password fields
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [passwordConstraints] = useState(
    "Password must be at least 6 characters long, contain at least one digit (0-9), one non-alphanumeric character, and one uppercase letter (A-Z)."
  );

  useEffect(() => {
    fetchProfileData();
  }, []);

  const fetchProfileData = async () => {
    try {
      const response = await getData(API_MODULES.PROFILE,"getuserProfileDetails");
      if(response.success){
        const profileDataFromAPI = response.data;
        const formattedProfileData = {
          firstName: profileDataFromAPI.firstName || "",
          lastName: profileDataFromAPI.lastName || "",
          affiliation: profileDataFromAPI.affiliation || "",
          email: profileDataFromAPI.email || "",
          title: profileDataFromAPI.title || "", 
        };
        setProfileData(formattedProfileData);
      }
      else{
        console.error("Error fetching profile data:", response.message);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  // Handle input changes for profile fields
  const handleProfileInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevProfileData) => ({
      ...prevProfileData,
      [name]: value,
    }));
  };

  // Handle input changes for password fields
  const handlePasswordInputChange = (e) => {
    const { name, value } = e.target;
    setPasswordData((prevPasswordData) => ({
      ...prevPasswordData,
      [name]: value,
    }));
  };

  // Validate password and set error messages
  const validatePassword = (password) => {
    let errorMessage = "";

    if (password.length < 6) {
      errorMessage += "Password must be at least 6 characters long. ";
    }
    if (!/\d/.test(password)) {
      errorMessage += "Password must contain at least one digit (0-9). ";
    }
    if (!/\W/.test(password) && !/_/.test(password)) {
      errorMessage += "Password must contain at least one non-alphanumeric character. ";
    }
    if (!/[A-Z]/.test(password)) {
      errorMessage += "Password must contain at least one uppercase letter (A-Z). ";
    }

    return errorMessage.trim();
  };

  const handlePasswordBlur = () => {
    if (passwordData.newPassword) {
      const validationMessage = validatePassword(passwordData.newPassword);
      setPasswordError(validationMessage);
    }
  };

  const handleConfirmPasswordBlur = () => {
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
    } else {
      setConfirmPasswordError("");
    }
  };

  // Validate current password by making an API call on blur
  const handleCurrentPasswordBlur = async () => {
    try {
      const response = await postData(API_MODULES.PROFILE, "validatePassword", {
        currentPassword: passwordData.currentPassword,
      });
      if (response.status !== 200) {
        setCurrentPasswordError("Current password is incorrect");
      } else {
        setCurrentPasswordError("");
      }
    } catch (error) {
      console.error("Error validating current password:", error);
      setCurrentPasswordError("Error validating current password");
    }
  };

  // Handle Update action for Profile
  const handleProfileUpdate = async () => {
    try {
      await postData(API_MODULES.PROFILE, "updateProfile", profileData);
      toast.success("Profile updated successfully");
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Error updating profile");
    }
  };

  // Handle Update action for Password
  const handlePasswordUpdate = async () => {
    if (passwordError || confirmPasswordError || currentPasswordError) {
      toast.error("Please fix the errors before updating the password");
      return;
    }

    try {
      await postData(API_MODULES.PROFILE, "changePassword", passwordData);
      toast.success("Password updated successfully");
    } catch (error) {
      console.error("Error updating password:", error);
      toast.error("Error updating password");
    }
  };

  return (
    <div className="edit-profile-container">
      <div className="header">
        <div className="title">
          <div className="background-image" />
          <h1 className="section-title">
            <span className="black-on-gold">Profile</span>
          </h1>
          <p>Edit user information or change your password.</p>
        </div>
      </div>

      <div className="content-container">
        <div className="tabs-container">
          <button
            className={`tab-button ${activeTab === "profile" ? "active" : ""}`}
            onClick={() => setActiveTab("profile")}
          >
            Update Profile
          </button>
          <button
            className={`tab-button ${activeTab === "password" ? "active" : ""}`}
            onClick={() => setActiveTab("password")}
          >
            Change Password
          </button>
        </div>

        <div className="form-container">
          {activeTab === "profile" ? (
            <form className="edit-profile-form">
              <div className="input-container">
                <input
                  type="text"
                  name="firstName"
                  value={profileData.firstName}
                  onChange={handleProfileInputChange}
                  required
                />
                <label>First Name</label>
              </div>
              <div className="input-container">
                <input
                  type="text"
                  name="lastName"
                  value={profileData.lastName}
                  onChange={handleProfileInputChange}
                  required
                />
                <label>Last Name</label>
              </div>

              {/* Affiliation (Disabled) */}
              <div className="input-container disabled">
                <input
                  type="text"
                  name="affiliation"
                  value={profileData.affiliation}
                  disabled
                />
                <label>Affiliation</label>
              </div>

              {/* Email (Disabled) */}
              <div className="input-container disabled">
                <input
                  type="text"
                  name="email"
                  value={profileData.email}
                  disabled
                />
                <label>Email</label>
              </div>

              <div className="input-container">
                <input
                  type="text"
                  name="title"
                  value={profileData.title}
                  onChange={handleProfileInputChange}
                />
                <label>Title</label>
              </div>

              <div className="form-actions">
                <Button variant="outlined" color="secondary">
                  Clear Changes
                </Button>
                <Button variant="contained" color="primary" onClick={handleProfileUpdate}>
                  Update
                </Button>
              </div>
            </form>
          ) : (
            <form className="edit-profile-form">
              <div className="input-container">
                <input
                  type="password"
                  name="currentPassword"
                  value={passwordData.currentPassword}
                  onChange={handlePasswordInputChange}
                  onBlur={handleCurrentPasswordBlur}
                  required
                />
                <label>Current Password</label>
                {currentPasswordError && (
                  <div className="error-message">{currentPasswordError}</div>
                )}
              </div>

              <div className="input-container">
                <input
                  type="password"
                  name="newPassword"
                  value={passwordData.newPassword}
                  onChange={handlePasswordInputChange}
                  onBlur={handlePasswordBlur}
                  required
                />
                <label>New Password</label>
                <span className="info-icon">
                  <Tooltip title={passwordConstraints} placement="bottom-start">
                    <FontAwesomeIcon icon={faCircleInfo} />
                  </Tooltip>
                </span>
                {passwordError && <div className="error-message">{passwordError}</div>}
              </div>

              <div className="input-container">
                <input
                  type="password"
                  name="confirmPassword"
                  value={passwordData.confirmPassword}
                  onChange={handlePasswordInputChange}
                  onBlur={handleConfirmPasswordBlur}
                  required
                />
                <label>Confirm New Password</label>
                {confirmPasswordError && (
                  <div className="error-message">{confirmPasswordError}</div>
                )}
              </div>

              <div className="form-actions">
                <Button variant="contained" color="primary" onClick={handlePasswordUpdate}>
                  Update
                </Button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
