import React from "react";
import "./PageNotFound.css";

const PageNotFound = () => {
  return (
    <div className="pagenotfound-container">
      <div className="main-container">
        <h1>404 - Page Not Found</h1>
        <p>The page you are looking for does not exist</p>
      </div>
    </div>
  );
};

export default PageNotFound;
