import React, { useState } from "react";
import peopleDetails from "./PeopleDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import "./People.css";

const People = () => {
  return (
    <div className="people-container">
      <div className="people-header">
        <div className="people-title">
          <div className="background-image" />
          <h1 className="section-title">
            <span className="black-on-gold">Meet the team</span>
          </h1>
          <p>
            Meet our talented team, the brains behind the Knowledge Alliance
            Tool's conception and development
          </p>
        </div>
        {/* <span className="title-description">
            A tool for identifying opportunities for collaboration between
            university researchers and community organizations in a major
            metropolitan area
          </span> */}
      </div>
      <div className="people-body">
        <div className="people-cards-container">
          {peopleDetails.map((item, index) => (
            <div className="people-card" key={index}>
              {item.profileURL ? (
                <a
                  href={item.profileURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <img src={item.image} alt="Image" />
                  <div className="people-info">
                    <h4>
                      <b>{item.name}</b>
                    </h4>
                    <p>{item.designation}</p>
                    <a href={`mailto:${item.email}`}>
                      <FontAwesomeIcon icon={faEnvelope} /> {item.email}
                    </a>
                  </div>
                </a>
              ) : (
                <div style={{ textDecoration: "none", color: "inherit" }}>
                  <img src={item.image} alt="Image" />
                  <div className="people-info">
                    <h4>
                      <b>{item.name}</b>
                    </h4>
                    <p>{item.designation}</p>
                    <a href={`mailto:${item.email}`}>
                      <FontAwesomeIcon icon={faEnvelope} /> {item.email}
                    </a>
                  </div>
                </div>
              )}
            </div>
          ))}

          {/* <div className="people-card">
              <img src={peopleImages.ker_mason} alt="Image"/>
              <div className="people-info">
                <h4><b>Mason Mathews</b></h4> 
                <p>Assistant Research Professor and Associate Director for Academic Integration and Alliances, Knowledge Exchange for Resilience</p>
                <a href="mailto:mason.mathews@example.com">
                  <FontAwesomeIcon icon={faEnvelope} /> mason.mathews@asu.edu
                </a>
              </div>
            </div> */}
        </div>
      </div>
    </div>
  );
};

export default People;
