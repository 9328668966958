// NavMenu.js
import React, { useState, useEffect, useRef } from "react";
import { Navbar, NavbarBrand, Nav, NavItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import SessionManager from "../Auth/SessionManager";
import asuKerLogo from "../../images/asu_ker_logo.png";
import Select, { components } from "react-select";
import "./NavMenu.css";
import { postData } from "../Services/AccessAPI";
import { API_MODULES } from "../Services/Settings";
import { toast } from "react-toastify";

// Forward refs in custom components
const CustomOption = React.forwardRef(({ isDisabled, isFocused, isSelected, children, innerProps, ...rest }, ref) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  const backgroundColor = isFocused ? "#eee" : isActive ? "#B2D4FF" : "transparent";

  const style = {
    alignItems: "center",
    backgroundColor,
    color: "inherit",
    display: "flex",
    cursor: "pointer",
    width: "100%",
    boxSizing: "border-box"
  };

  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
    ref
  };

  return (
    <components.Option {...rest} isDisabled={isDisabled} isFocused={isFocused} isSelected={isSelected} innerProps={props}>
      {children}
    </components.Option>
  );
});

const CustomValueContainer = React.forwardRef(({ children, ...props }, ref) => {
  const [value, input] = children;
  return (
    <components.ValueContainer {...props} ref={ref}>
      {value}
      {input}
    </components.ValueContainer>
  );
});

const NavMenu = ({ user, userRole, onGroupChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const isSuperAdmin = Array.isArray(userRole)
    ? userRole.includes("SUPERADMIN")
    : userRole === "SUPERADMIN";
  // const isTrialUser = (typeof(userRole) === "string") && (userRole === "TRIALUSERROLE");
  const history = useHistory();

  const userName = localStorage.getItem("userName") || "User";
  const userInitials = userName.split(" ").map(name => name[0]).join("");

  const handleLogout = () => {
    let logoutParams = {
      user_id: localStorage.getItem('user_id')
    }
    postData(API_MODULES.LOGIN, 'logout', logoutParams)
    .then((response) => {
      SessionManager.removeUserSession();
      toast.success("Successfully logged out."); // Display success message
      window.location.href = "/login";
    })
    .catch((error) => {
      console.error("Logout error:", error);
      toast.error("Failed to log out. Please try again."); // Display error message
    });
  };

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const customStyles = {
    control: provided => ({
      ...provided,
      border: "none",
      boxShadow: "none",
      backgroundColor: "transparent",
      color: "#8c1d40",
      fontWeight: "bold",
      cursor: "pointer"
    }),
    singleValue: provided => ({
      ...provided,
      color: "maroon",
      fontWeight: "bold",
      cursor: "pointer"
    }),
    indicatorSeparator: provided => ({
      display: "none"
    }),
    indicatorsContainer: provided => ({
      ...provided,
      paddingLeft: 0
    }),
    dropdownIndicator: provided => ({
      ...provided,
      paddingLeft: 0
    }),
    menu: provided => ({
      ...provided,
      width: "auto",
      minWidth: "120px"
    }),
    option: (provided, state) => ({
      ...provided,
      width: "100%",
      boxSizing: "border-box",
      borderLeft: state.isSelected ? "4px solid maroon" : "none"
    })
  };

  useEffect(() => {
    if (user && user.attached_policies) {
      const savedOption = localStorage.getItem("selectedGroup");
      const options = user.attached_policies.map(policy => ({
        value: policy.group_name,
        label: policy.group_name
      }));

      const initialOption = options.find(option => option?.value === savedOption) || options[0];
      setSelectedOption(initialOption);
      onGroupChange(initialOption?.value);
    }
  }, [user, onGroupChange]);

  const handleGroupChange = option => {
    setSelectedOption(option);
    onGroupChange(option?.value);
    localStorage.setItem("selectedGroup", option?.value);
    // history.push("/dashboard"); // Redirect to /dashboard
    window.location.href = "/dashboard";
  };

  return (
    <Navbar className="navbar" light expand="md">
      <NavbarBrand tag={Link} to="/">
        <img src={asuKerLogo} alt="Logo" className="logo" />
        <span className="nav-title" data-full="Knowledge Alliance Tool">
          Knowledge Alliance Tool
        </span>
      </NavbarBrand>
      <Nav className="nav-items" navbar>
        <NavItem>
          <Select
            value={selectedOption}
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            onChange={handleGroupChange}
            options={
              user
                ? user.attached_policies.map((policy) => ({
                    value: policy.group_name,
                    label: policy.group_name,
                  }))
                : []
            }
            components={{
              Option: CustomOption,
              ValueContainer: CustomValueContainer,
            }}
            styles={customStyles}
          />
        </NavItem>
        <div className="separator"></div>
        <NavItem ref={dropdownRef}>
          <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle className="user-profile">
              <div className="user-icon">{userInitials}</div>
            </DropdownToggle>
            <DropdownMenu right className="user-dropdown-menu">
              <DropdownItem header className="dropdown-header">
                <div className="dropdown-user-info">
                  <span>Hello {userName}!</span>
                  <span className="user-role-badge">{userRole}</span>
                  {/* {isTrialUser && (
                    <span className="user-role-badge">Trial User</span>
                  )}
                  {isSuperAdmin && (
                    <span className="user-role-badge">Super Admin</span>
                  )} */}
                </div>
              </DropdownItem>
              <DropdownItem onClick={() => history.push("/update-profile")}>
                  Update Profile
              </DropdownItem>
              {isSuperAdmin && (
                <DropdownItem onClick={() => history.push("/admin")}>
                  Admin Portal
                </DropdownItem>
              )}
              <DropdownItem divider />
              <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default NavMenu;
