import React, { useState } from "react";
import asu_ker_logo from "../../images/asu_ker_logo.png";
import { toast } from "react-toastify";
import { postDataForLoginAndRegister } from "../Services/AccessAPI";
import SessionManager from "./SessionManager";
import { Link } from "react-router-dom";
import "./Login.css";
import "./ResetPassword.css";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      setPassword(value);
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  const handleInputFocus = (e) => {
    const inputContainer = e.target.parentNode;
    inputContainer.classList.add("focused");
  };

  const handleInputBlur = (e) => {
    const inputContainer = e.target.parentNode;
    if (e.target.value === "") {
      inputContainer.classList.remove("focused");
    }
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const validatePassword = (password) => {
    let errorMessage = '';
  
    // Check for minimum length
    if (password.length < 6) {
      errorMessage += 'Password must be at least 6 characters long. ';
    }
  
    // Check for a digit
    if (!/\d/.test(password)) {
      errorMessage += 'Password must contain at least one digit (0-9). ';
    }
  
    // Check for a non-alphanumeric character
    if (!/\W/.test(password) && !/_/.test(password)) { // \W is non-word characters, added underscore as it's considered a word char
      errorMessage += 'Password must have at least one non-alphanumeric character. ';
    }
  
    // Check for an uppercase letter
    if (!/[A-Z]/.test(password)) {
      errorMessage += 'Password must have at least one uppercase letter (A-Z). ';
    }
  
    return errorMessage.trim(); // Trim any trailing space
  };
  
  const handlePasswordBlur = (e) => {
    handleInputBlur(e);
    const validationMessage = validatePassword(password);
    setPasswordError(validationMessage);
  };
  

  const handlePasswordMismatchBlur = (e) => {
    handleInputBlur(e);
    if (!passwordError && confirmPassword) {
      const errorMessage =
        password !== confirmPassword ? "Passwords do not match" : "";
      setPasswordError(errorMessage);
    }
  };

  const handleSubmit = async (e) => {
    // try {
    //   e.preventDefault();
    //   const loadId = toast.loading("Validating Credentials");
    //   const userInfo = {
    //     email: email,
    //     password: password,
    //     rememberMe: true,
    //   };

    //   postDataForLoginAndRegister("api/Login", userInfo).then((result) => {
    //     if (result?.data?.access_token) {
    //       SessionManager.setUserSession(
    //         result.data.access_token,
    //         result.data.userId,
    //         email
    //       );

    //       if (SessionManager.getToken()) {
    //         console.log("Login successful!");
    //         toast.update(loadId, {
    //           render: "Login successful!",
    //           type: "success",
    //           isLoading: false,
    //           autoClose: 4000,
    //           hideProgressBar: false,
    //           closeOnClick: true,
    //         });
    //         // If login successful and get token
    //         // redirect to dashboard
    //         window.location.href = "/dashboard";
    //       }
    //     } else {
    //       toast.update(loadId, {
    //         render: "Incorrect Login Credentials!",
    //         type: "error",
    //         isLoading: false,
    //         autoClose: 4000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //       });
    //       // toast.error("Incorrect Username or Password");
    //     }
    //   });
    // } catch (error) {
    //   console.error("Error Logging in: ", error);
    // }
  };

  return (
    <div>
      <div className="reset-password-container">
        <div className="login-box">
          <img src={asu_ker_logo} alt="Login" className="login-image" />
          <h2>Knowledge Alliance Tool</h2>
          <span className="info">Enter your new password</span>
          <form onSubmit={handleSubmit}>
          <div className="input-container">
            <input
              type="password"
              name="password"
              value={password}
              minLength="6"
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onBlur={handlePasswordBlur}
              required
            />
            <label>New Password</label>
            {passwordError && <div className="error-message">{passwordError}</div>}
          </div>
          <div className="input-container">
            <input
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              minLength="6"
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onBlur={handlePasswordMismatchBlur}
              required
            />
            <label>Confirm Password</label>
          </div>
            <button type="submit">Submit</button>
          </form>
          <Link to="/login">
            <span className="login-btn">Back to login</span>
          </Link>
        </div>
        <div className="login-footer-info">
          <h1>Knowledge Exchange For Resilience</h1>
          <p>©2018 All Rights Reserved. Knowledge Exchange For Resilience!</p>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
