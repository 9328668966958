import { capitalizeFirstLetter, decodeJWT } from "../Utilities/utils";

const SessionManager = {
  getToken: () => localStorage.getItem("access_token") || null,

  setUserSession: (access_token, userName, refresh_token) => {
    localStorage.setItem("access_token", access_token);
    localStorage.setItem("userName", capitalizeFirstLetter(userName));
    localStorage.setItem("refresh_token", refresh_token);
  },

  setSelectedGroup: () => {
    const token = SessionManager.getToken();
    if (token) {
      const attachedPolicy = SessionManager.getAttachedPolicyFromToken(token);
      if (attachedPolicy && !localStorage.getItem("selectedGroup")) {
        localStorage.setItem("selectedGroup", attachedPolicy?.attached_policies[0]?.group_name);
      }
    }
  },

  removeUserSession: () => {
    ["access_token", "refresh_token", "userName", "user_id", "selectedGroup"].forEach(item => localStorage.removeItem(item));
  },

  getUserFromToken: () => {
    const token = SessionManager.getToken();
    if (token) {
      const decodedToken = SessionManager.decodeToken(token);
      const role = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      const attachedPolicy = JSON.parse(decodedToken.AttachedPolicies);
      localStorage.setItem('user_id', attachedPolicy?.user_id); // temporary purpose
      return { attachedPolicy, role };
    }
    return null;
  },

  decodeToken: (token) => token ? decodeJWT(token) : null,

  getAttachedPolicyFromToken: (token) => {
    const decodedToken = SessionManager.decodeToken(token);
    return decodedToken ? JSON.parse(decodedToken.AttachedPolicies) : null;
  }
};

export default SessionManager;
