import SessionManager from "../components/Auth/SessionManager"
let group_name = `group_name=${localStorage.getItem('selectedGroup')}`;

let user__id = localStorage.getItem('user_id');
if (!user__id) {
  const user = SessionManager.getUserFromToken();
  if (user && user.attachedPolicy) {
    user__id = user.attachedPolicy.user_id;
    localStorage.setItem('user_id', user__id);
  }
}

const API_ENDPOINTS = {
  Admin: {
    getAllAffiliations: "get-all-affiliations-super-admin-panel",
    getAllFeatures: "get-all-features",
    activeOrganisations: "get-active-organisations",
    attachPoliciesToAffiliation: "attach-policies-to-affiliation",
    getAffiliationGroupFeatureMapping: "get-affiliation-group-feature-mapping?affiliation_id={affiliation_id}",
    getSubscriptionForAffiliation: "get-subscription-for-affiliation?p_affiliate_id={affiliation_id}",
    updateSubscriptionPlan: "update-subscription-plan",
    createAffiliation: "create-affiliation?affiliation_name={affiliation_name}&domain_url={domain_url}&domain_name={domain_name}",
    createGroup: "create-group?p_group_name={group_name}",
  },
  Profile: { // TODO: replace with actual endpoints when ready
    validatePassword: "Login/validate-password"+ `?user_id=${user__id}`+"&password={currentPassword}",
    getuserProfileDetails: "api/Registration/get-user-profile-details"+ `?user_id=${user__id}`,
    updateProfile: "api/Registration/update-profile" + `?u_id=${user__id}` + "&u_firstname={firstName}&u_lastname={lastName}&u_title={title}",
    changePassword: "Login/change-password"+`?user_id=${user__id}`+"&old_password={currentPassword}&new_password={newPassword}",
  },
  Register: {
    getAllAffiliations: "api/Registration/get-all-affiliations",
    getValidAffiliations: "api/Registration/get-valid-affiliations?domain_name={domain_name}",
    register: "api/Registration/register?email={email}&password={password}&FirstName={FirstName}&LastName={LastName}&affiliationId={affiliationId}&domain_name={domain_name}&affiliation_name={affiliation_name}&affiliation_url={affiliation_url}&Title={Title}"
  },
  Login: {
    login: "Login/Login?email={email}&password={password}&rememberMe={rememberMe}",
    logout: "Login/logout" + `?user_id=${user__id}`,
  },
  Dashboard: {
    getDashboardCardStats: "api/Dashboard/getDashboardCardStats" + `?${group_name}`,
    getPopularTags: "api/Tags/getPopularTags" ,
    getSearchesByMonth: "api/Dashboard/getSearchesByMonth" + `?${group_name}`,
    getSearchHistoryKeyword: "api/Dashboard/getSearchHistoryKeyword" + `?${group_name}`,
    getTopKeywordsByLocation: "api/Dashboard/getTopKeywordsByLocation" + `?${group_name}`,
  },
  Search: {
    // Shared Topics
    getOrganizationDetails: "api/SearchSharedTopics/getOrganizationDetails?organizationName={organizationName}" + `&${group_name}`,
    getFacultyDetails: "api/SearchSharedTopics/getFacultyDetails?facultyName={facultyName}" + `&${group_name}`,
    getCommonBigrams: "api/Bigrams/getCommonBigrams?organizationName={organizationName}&facultyName={facultyName}&edgeType={edgeType}" + `&${group_name}`,
    getCommonBigramsListForOrgToOrg: "api/Bigrams/getCommonBigramsListForOrgToOrg?sorgname={sorgname}&torgname={torgname}&edgeType={edgeType}" + `&${group_name}`,
    getCommonBigramsListForFacultyToFaculty: "api/Bigrams/getCommonBigramsListForFacultyToFaculty?sfacultyname={sfacultyname}&tfacultyname={tfacultyname}&edgeType={edgeType}" + `&${group_name}`,
    getHiddenBigrams: "api/Bigrams/getHiddenBigrams",
    getFavouriteBigrams: "api/Bigrams/getFavouriteBigrams",
    
    insertHiddenBigrams: "api/Bigrams/insertHiddenBigrams?bigram_in={bigram_in}",
    unhideBigrams: "api/Bigrams/unhideBigrams?bigram_in={bigram_in}",
    insertFavouriteBigrams: "api/Bigrams/insertFavouriteBigrams?bigram_in={bigram_in}",
    removeBigramFromFav: "api/Bigrams/removeBigramFromFav?bigram_in={bigram_in}",

    getDefaultOrgToFaculty: "api/OrgToFaculty/getDefaultOrgToFaculty" + `?${group_name}`,
    getDefaultOrgToOrg: "api/SearchSharedTopics/getDefaultOrgToOrg" + `?${group_name}`,
    getDefaultFacultyToFaculty: "api/SearchSharedTopics/getDefaultFacultyToFaculty" + `?${group_name}`,
    getOrgToFacultySearchResults: "api/OrgToFaculty/getOrgToFacultySearchResults?searchKeyword={searchKeyword}&geoData={geoData}" + `&${group_name}`,
    getOrgToOrgSearchResults: "api/SearchSharedTopics/getOrgToOrgSearchResults?searchKeyword={searchKeyword}" + `&${group_name}`,
    getFacultyToFacultySearchResults: "api/SearchSharedTopics/getFacultyToFacultySearchResults?searchKeyword={searchKeyword}" + `&${group_name}`,
    // Keywords
    getKeywordSearchResults: "api/SearchKeywords/getKeywordSearchResults?searchText={searchText}" + `&${group_name}`,
    getOrgFacultyListForKeyword: "api/SearchKeywords/getOrgFacultyListForKeyword?searchText={searchText}" + `&${group_name}`,
    // Expertise
    getFacultyListByExpertiseArea: "api/Faculty/getFacultyListByExpertiseArea?expertisearea={expertisearea}&geoData={geoData}" + `&${group_name}`,
    getFacultyListByExpertiseAreaForExport: "api/Faculty/getFacultyListByExpertiseAreaForExport?expertisearea={expertisearea}" + `&${group_name}`,
    getFacultyDetailedProfile: "api/Faculty/getFacultyDetailedProfile?r_staffid={staffid}" + `&${group_name}`,
  },
  Tags: {
    getTags: "api/Tags/getTags",
    getTagCsv: "api/Tags/getTagCsv?tag_id={tag_id}" + `&${group_name}`,
    getFacultiesForTag: "api/Tags/getFacultiesForTag?tag_label={tag_label}" + `&${group_name}`,
    insertTagForFaculty: "api/Tags/insertTagForFaculty?tag_label={tag_label}&faculty_id={faculty_id}",
    updateTagItemsForFaculty: "api/Tags/updateTagItemsForFaculty?faculty_id={faculty_id}&tag_id={tag_id}",
    removeTagByIdForFaculty: "api/Tags/removeTagByIdForFaculty?searchtext={searchtext}&faculty_id={faculty_id}",
    editTag: "api/Tags/editTag?tag_id={tag_id}&new_taglabel={new_taglabel}",
    deleteTag: "api/Tags/deleteTag?tag_id={tag_id}",
  },
  Upload: {
    fileUpload: "api/KeywordUpload/File_Upload",
    keywordsInfo: "api/KeywordUpload/keywordsInfo",
    downloadCsv: "api/KeywordUpload/DownloadCSV?keyword={keyword}",
  }
};

export default API_ENDPOINTS;