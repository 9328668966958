import React, { useEffect, useRef } from 'react';
import { getData } from '../Services/AccessAPI';
import L from 'leaflet';
import './Map.css';
import 'leaflet.markercluster';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import { API_MODULES } from '../Services/Settings';

const Map = () => {
  const mapRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getData(API_MODULES.DASHBOARD, 'getTopKeywordsByLocation');
        initializeMap(data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const initializeMap = (markersData) => {
    const map = L.map(mapRef.current).setView([39.8283, -98.5795], 4); // Set initial map center and zoom level

    // Add OpenStreetMap tile layer
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
      maxZoom: 12,
      minZoom: 2,
    }).addTo(map);

    const markers = createMarkers(markersData, map);
    const markerCluster = L.markerClusterGroup();

    markers.forEach((marker) => {
      markerCluster.addLayer(marker);
    });

    map.addLayer(markerCluster);
  };

  const createMarkers = (markersData, map) => {
    const markers = [];

    markersData.forEach((markerData) => {
      const { searchKeyword, searchCount, location } = markerData;
      const [lat, lng] = location.split(',');

      if (lat !== undefined && lng !== undefined) {
        const marker = L.marker([parseFloat(lat), parseFloat(lng)]).bindPopup(
          `<strong>${searchKeyword}</strong><br>Search Count: ${searchCount}`
        );
        markers.push(marker);
      }
    });

    return markers;
  };

  return <div ref={mapRef} style={{ height: '360px', borderRadius: '6px' }} />;
};

export default Map;
