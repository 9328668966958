import React, { useState } from "react";
import { useTable, usePagination, useSortBy, useFilters } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
  faFilter,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import "./TableWithAccordian.css";
import { ColumnFilter } from "../Utilities/Table/ColumnFilter";
import { Tooltip } from "@mui/material";

const TableWithAccordian_New = ({
  columns = [],
  data = [],
  pageSizeOptions = [10, 25, 50],
  showPagination = true,
  showFilterOptions = true,
  disableFilterOptionsForIds = [],
  defaultShowFilterForIds = [],
  tableDataHeight,
}) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [activeFilterColumns, setActiveFilterColumns] = useState(
    defaultShowFilterForIds
  );
  const [expandedRows, setExpandedRows] = useState({});

  const defaultColumn = React.useMemo(
    () => ({
      Filter: ColumnFilter,
    }),
    []
  );
  // Table rendering logic goes here
  // Create a table instance with pagination and sorting features
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      style: {
        height: "400px", // Set the height of the table body
      },
      defaultColumn,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const handleToggleSelectedRow = (rowId) => {
    setSelectedRow((prevSelectedRow) =>
      prevSelectedRow === rowId ? null : rowId
    );
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [rowId]: !prevExpandedRows[rowId],
    }));
  };

  // const handleToggleRow = (rowId) => {
  //   setExpandedRows((prevExpandedRows) => ({
  //     ...prevExpandedRows,
  //     [rowId]: !prevExpandedRows[rowId],
  //   }));
  // };

  return (
    <div className="react-table-container">
      <div
        className="table-scroll-container"
        style={tableDataHeight ? { height: tableDataHeight } : null}
      >
        <table {...getTableProps()} className="react-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) =>
                  showFilterOptions &&
                  !disableFilterOptionsForIds.includes(column.id) ? (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={
                        column.isSorted
                          ? column.isSortedDesc
                            ? "desc"
                            : "asc"
                          : ""
                      }
                    >
                      <div className="column-header" title="">
                        <div className="column-header-title">
                          <Tooltip title="Toggle Sort" placement="bottom-end">
                            <span className="header-sort">
                              <span>{column.render("Header")}</span>
                              <span className="sort-icon">
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <FontAwesomeIcon icon={faSortDown} />
                                  ) : (
                                    <FontAwesomeIcon icon={faSortUp} />
                                  )
                                ) : (
                                  <FontAwesomeIcon icon={faSort} />
                                )}
                              </span>
                            </span>
                          </Tooltip>
                          <Tooltip
                            title="Toggle Filter"
                            placement="bottom-start"
                          >
                            <span
                              className={`filter-icon ${
                                activeFilterColumns.includes(column.id)
                                  ? "active"
                                  : ""
                              }`}
                              onClick={(e) => {
                                e.stopPropagation();
                                setActiveFilterColumns((prevColumns) => {
                                  if (prevColumns.includes(column.id)) {
                                    return prevColumns.filter(
                                      (id) => id !== column.id
                                    );
                                  } else {
                                    return [...prevColumns, column.id];
                                  }
                                });
                              }}
                            >
                              <FontAwesomeIcon icon={faFilter} />
                            </span>
                          </Tooltip>
                        </div>
                        {activeFilterColumns.includes(column.id) && (
                          <div className="column-header-filter">
                            {column.canFilter ? (
                              <div onClick={(e) => e.stopPropagation()}>
                                {column.render("Filter")}
                              </div>
                            ) : null}
                          </div>
                        )}
                      </div>
                    </th>
                  ) : (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  )
                )}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="table-body-container">
            {showPagination
              ? page.map((row) => {
                  prepareRow(row);
                  const isSelected = row.id === selectedRow;

                  return (
                    <React.Fragment key={row.id}>
                      <tr
                        {...row.getRowProps()}
                        className={isSelected ? "selected-row" : ""}
                      >
                        {row.cells.map((cell, index) => (
                          <td
                            {...cell.getCellProps()}
                            onClick={() => handleToggleSelectedRow(row.id)}
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              paddingRight: "16px",
                              justifyContent: "space-between",
                            }}
                          >
                            {cell.render("Cell")}
                            {index === row.cells.length - 1 && (
                              <span style={{ marginLeft: "0.5rem" }}>
                                <FontAwesomeIcon
                                  icon={
                                    expandedRows[row.id]
                                      ? faChevronUp
                                      : faChevronDown
                                  }
                                  className="accordion-icon"
                                />
                              </span>
                            )}
                          </td>
                        ))}
                      </tr>
                      {expandedRows[row.id] && (
                        <tr>
                          <td className="accordian-text">
                            <span className="accordian-text header">
                              <span className="text-row">
                                <span className="text-title">Email: </span>
                                <span className="text-data">
                                  {row.original.Email}
                                </span>
                              </span>
                              <span className="text-row">
                                <span className="text-title">Department: </span>
                                <span className="text-data">
                                  {row.original.Department}
                                </span>
                              </span>
                              <span className="text-row">
                                <span className="text-title">
                                  Designation:{" "}
                                </span>
                                <span className="text-data">
                                  {row.original.Designation.endsWith(",")
                                    ? row.original.Designation.slice(0, -1)
                                    : row.original.Designation}
                                </span>
                              </span>
                              <span className="text-row">
                                <span className="text-title">
                                  Aggregated Similarity Score:{" "}
                                </span>
                                <span className="text-data">
                                  {(
                                    row.original.AggregatedSimilarityScore * 100
                                  ).toFixed(2) + "%"}
                                </span>
                              </span>
                            </span>
                            <span className="accordian-text body">
                              <span className="text-title">
                                Matching text:{" "}
                              </span>
                              <div className="text-data">
                                <ul>
                                  {row.original.MostSimilarChunks.map(
                                    (chunk, index) => (
                                      <li key={index}>{chunk}</li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </span>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })
              : rows.map((row) => {
                  prepareRow(row);
                  const isSelected = row.id === selectedRow;

                  return (
                    <tr
                      {...row.getRowProps()}
                      className={isSelected ? "selected-row" : ""}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            onClick={() => handleToggleSelectedRow(row.id)}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>

      {showPagination && (
        <div className="pagination">
          <span className="page-details">
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageCount}
            </strong>{" "}
          </span>
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </button>
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>
          <div className="pagination-options">
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {pageSizeOptions.map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {"Show "}
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableWithAccordian_New;
