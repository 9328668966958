import KER_Mason from '../../images/Team/KER_Mason.png';
import KER_Brajesh from '../../images/Team/KER_Brajesh.png';
import KER_Patricia from '../../images/Team/KER_Patricia.png';
import KER_Kevin from '../../images/Team/KER_Kevin.png';
import KER_Akshay from '../../images/Team/KER_Akshay.png';
import KER_Amal from '../../images/Team/KER_Amal.jpg';
import KER_Nikhil from '../../images/Team/KER_Nikhil.jpg';
import KER_Saketh from '../../images/Team/KER_Saketh.jpg';
import KER_Aayush from '../../images/Team/KER_Aayush.jpg';
import KER_Aayushi from '../../images/Team/KER_Aayushi.jpg';
import Avatar from '../../images/Team/Avatar.png';

const peopleDetails = [
    
    {
        name: "Brajesh Karna",
        image: KER_Brajesh,
        email: "Brajesh.Karna@asu.edu",
        profileURL: "https://resilience.asu.edu/karna",
        designation: "Data Manager (Sr), Knowledge Exchange for Resilience",
    },
    {
        name: "Patricia Solis",
        image: KER_Patricia,
        email: "patricia.solis@asu.edu",
        profileURL: "https://resilience.asu.edu/solis",
        designation: "Executive Director, Knowledge Exchange for Resilience",
    },
    {
        name: "Mason Mathews",
        image: KER_Mason,
        email: "Mason.Mathews@asu.edu",
        profileURL: "https://resilience.asu.edu/mathews",
        designation: "Assistant Research Professor and Associate Director for Academic Integration and Alliances, Knowledge Exchange for Resilience",
    },
    {
        name: "Kevin Vora",
        image: KER_Kevin,
        email: "kvora1@asu.edu",
        profileURL: "https://resilience.asu.edu/vora",
        designation: "Graduate Research Assistant, Knowledge Exchange for Resilience",
    },
    {
        name: "Akshay Reddy Kola",
        image: KER_Akshay,
        email: "akola1@asu.edu",
        profileURL: "https://resilience.asu.edu/Akshay",
        designation: "Research Aide, Knowledge Exchange for Resilience",
    },
    {
        name: "Nikhil Kumar",
        image: KER_Nikhil,
        email: "nkumar82@asu.edu",
        profileURL: "https://resilience.asu.edu/Kumar",
        designation: "Research Aide, Knowledge Exchange for Resilience",
    },
    {
        name: "Aayush Kansara",
        image: KER_Aayush,
        email: "akansara@asu.edu",
        profileURL: "",
        designation: "Research Aide, Knowledge Exchange for Resilience",
    },
    {
        name: "Aayushi",
        image: KER_Aayushi,
        email: "anola184@asu.edu",
        profileURL: "",
        designation: "Research Aide, Knowledge Exchange for Resilience",
    },
     {
        name: "Amal Shaik",
        image: KER_Amal,
        email: "ashaik22@asu.edu",
        profileURL: "https://resilience.asu.edu/Shaik",
        designation: "Research Aide, Knowledge Exchange for Resilience",
    },
    {
        name: "Saketh Angirekula",
        image: KER_Saketh,
        email: "sangirek@asu.edu",
        profileURL: "",
        designation: "Research Aide, Knowledge Exchange for Resilience",
    },
    {
        name: "Archit Agrawal",
        image: Avatar,
        email: "aagra108@asu.edu",
        profileURL: "",
        designation: "Research Aide, Knowledge Exchange for Resilience",
    },
    {
        name: "Ansh Tiwari",
        image: Avatar,
        email: "atiwar31@asu.edu",
        profileURL: "",
        designation: "Research Aide, Knowledge Exchange for Resilience",
    },
]

export default peopleDetails;
