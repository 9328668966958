import React, { useState, useMemo, useEffect } from "react";
import Table from "../Utilities/Table/Table";
import { getData } from "../Services/AccessAPI";
import { toast } from "react-toastify";
import { Select, MenuItem, FormControl, InputLabel, CircularProgress, Tooltip, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "../Utilities/LoadingSpinner";
import { API_MODULES } from "../Services/Settings";

const Users = ({ affiliations }) => {
  const [selectedAffiliation, setSelectedAffiliation] = useState("");
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [features, setFeatures] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isViewClicked, setIsViewClicked] = useState(false);

  useEffect(() => {
    if (selectedAffiliation) {
      fetchUserData(selectedAffiliation);
      fetchGroupAndFeatureData(selectedAffiliation);
    }
  }, [selectedAffiliation]);

  const fetchUserData = async (affiliationId) => {
    setIsLoading(true);
    try {
      const response = await getData(
        API_MODULES.ADMIN,
        "getUsersByAffiliation",
        {
          affiliation_id: affiliationId,
        }
      );
      setUsers(response || []);
      setIsViewClicked(true);
    } catch (error) {
      console.error("Error fetching users:", error);
      toast.error("Error fetching users");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchGroupAndFeatureData = async (affiliationId) => {
    try {
      const [groupsResponse, featuresResponse] = await Promise.all([
        getData(API_MODULES.ADMIN, "getGroupsByAffiliation", {
          affiliation_id: affiliationId,
        }),
        getData(API_MODULES.ADMIN, "getFeaturesByAffiliation", {
          affiliation_id: affiliationId,
        }),
      ]);
      setGroups(groupsResponse || []);
      setFeatures(featuresResponse || []);
    } catch (error) {
      console.error("Error fetching groups and features:", error);
      toast.error("Error fetching groups and features");
    }
  };

  const handleUpdateButtonClick = async () => {
    setIsUpdating(true);
    // Add update logic here
    try {
      // Update API call goes here
      toast.success("Updated successfully");
    } catch (error) {
      console.error("Error updating data:", error);
      toast.error("Failed to update data");
    } finally {
      setIsUpdating(false);
    }
  };

  const handleClearChanges = () => {
    setUsers([]);
    setGroups([]);
    setFeatures([]);
    setIsViewClicked(false);
  };

  const userColumns = useMemo(
    () => [
      {
        Header: "User",
        accessor: "user_name",
        sortType: "alphanumeric",
      },
      {
        Header: "Email",
        accessor: "email",
        sortType: "alphanumeric",
      },
    ],
    []
  );

  const groupColumns = useMemo(
    () => [
      {
        Header: "Group",
        accessor: "group_name",
        sortType: "alphanumeric",
      },
    ],
    []
  );

  const featureColumns = useMemo(
    () => [
      {
        Header: "Feature",
        accessor: "feature_name",
        sortType: "alphanumeric",
      },
    ],
    []
  );

  return (
    <>
      <FormControl variant="outlined" className="affiliation-select" sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel>Affiliation</InputLabel>
        <Select
          value={selectedAffiliation}
          onChange={(e) => setSelectedAffiliation(e.target.value)}
          label="Affiliation"
        >
          {affiliations.map((affiliation) => (
            <MenuItem
              key={affiliation.affiliate_id}
              value={affiliation.affiliate_id}
            >
              {affiliation.affiliate_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <div className="table-container">
        {isLoading && <LoadingSpinner />}
        <div className={`entity-data ${users.length > 0 ? "" : "full-width"}`}>
          {users.length > 0 ? (
            <Table
              columns={userColumns}
              data={users}
              disableFilterOptionsForIds={["action"]}
            />
          ) : (
            !isLoading && <p className="no-data-text">No users found</p>
          )}
        </div>
        {isViewClicked && (
          <div className="entity-view-data">
            <div className="entity-side-panel">
              <div className="groups-data">
                {groups.length > 0 ? (
                  <Table
                    columns={groupColumns}
                    data={groups}
                    disableFilterOptionsForIds={["checkbox"]}
                    tableDataHeight={"400px"}
                    showPagination={false}
                  />
                ) : (
                  <p className="no-data-text">No groups found</p>
                )}
              </div>
              <div className="features-data">
                {features.length > 0 ? (
                  <Table
                    columns={featureColumns}
                    data={features}
                    disableFilterOptionsForIds={["checkbox"]}
                    tableDataHeight={"400px"}
                    showPagination={false}
                  />
                ) : (
                  <p className="no-data-text">No features found</p>
                )}
              </div>
            </div>
            <div className="options-container">
              <Button
                variant="contained"
                color="secondary"
                className="option-button clear"
                onClick={handleClearChanges}
              >
                Clear Changes
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="option-button update"
                onClick={handleUpdateButtonClick}
                disabled={isUpdating}
              >
                {isUpdating && (
                  <CircularProgress
                    size={20}
                    style={{ marginRight: "8px", color: "white" }}
                  />
                )}
                Update
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Users;
